<template>
<div>
  <div v-if="$store.state.appConfig.appLoading" id="loading-bg">
    <div class="loading-logo">
      <img src="/logo.png" alt="Logo" />
    </div>
    <div class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>

  <layout-vertical v-show="!$store.state.appConfig.appLoading">
    <router-view />

        <app-customizer
          v-if="showCustomizer"
          slot="customizer"
        />

  </layout-vertical>
</div>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    AppCustomizer,
    LayoutVertical,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
