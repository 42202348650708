// Notification
// Or with import
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'
import {initialAbility} from '@/libs/acl/config'
import store from '@/store'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
    // Will be used by this service for making API calls
    axiosIns = null

    // jwtConfig <= Will be used by this service
    jwtConfig = {...jwtDefaultConfig}

    // For Refreshing Token
    isAlreadyFetchingAccessToken = false

    // For Refreshing Token
    subscribers = []

    constructor(axiosIns, jwtOverrideConfig) {
        this.axiosIns = axiosIns
        this.jwtConfig = {...this.jwtConfig, ...jwtOverrideConfig}

        // Request Interceptor
        this.axiosIns.interceptors.request.use(
            config => {
                // Get token from localStorage
                const accessToken = this.getToken()
                // If token is present add it to request's Authorization Header
                if (accessToken) {
                    // eslint-disable-next-line no-param-reassign
                    config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                }
                return config
            },
            error => Promise.reject(error),
        )

        // Add request/response interceptor
        this.axiosIns.interceptors.response.use(
            response => response,
            error => {
                // const { config, response: { status } } = error
                const {config, response} = error
                const originalRequest = config

                // if (status === 401) {
                if (response && response.status === 401) {
                    if (!this.isAlreadyFetchingAccessToken) {
                        this.isAlreadyFetchingAccessToken = true
                        this.refreshToken().then(r => {
                            this.isAlreadyFetchingAccessToken = false

                            // Update accessToken in localStorage
                            this.setToken(r.data.accessToken)
                            this.setRefreshToken(r.data.refreshToken)

                            this.onAccessTokenFetched(r.data.accessToken)
                        })
                    } else {
                        store.commit('auth/SET_ACCESS_TOKEN', null)
                        store.commit('auth/SET_REFRESH_TOKEN', null)
                        ability.update(initialAbility)
                        store.commit('auth/SET_USER_DATA', null)

                        // eslint-disable-next-line no-restricted-globals
                        location.reload()
                    }
                    const retryOriginalRequest = new Promise(resolve => {
                        this.addSubscriber(accessToken => {
                            // Make sure to assign accessToken according to your response.
                            // Check: https://pixinvent.ticksy.com/ticket/2413870
                            // Change Authorization header
                            originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                            resolve(this.axiosIns(originalRequest))
                        })
                    })
                    return retryOriginalRequest
                }

                // show errors if available
                if (response && response.data) {
                    Vue.$toast({
                        component: ToastificationContent,
                        props: {
                            title: response.data.detail,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                            validationErrors: response.data.errors
                        },
                    });
                }

                return Promise.reject(error)
            },
        )
    }

    onAccessTokenFetched(accessToken) {
        this.subscribers = this.subscribers.filter(callback => callback(accessToken))
    }

    addSubscriber(callback) {
        this.subscribers.push(callback)
    }

    // eslint-disable-next-line class-methods-use-this
    getToken() {
        return store.state.auth.accessToken
    }

    // eslint-disable-next-line class-methods-use-this
    getRefreshToken() {
        return store.state.auth.refreshToken
    }

    // eslint-disable-next-line class-methods-use-this
    setToken(value) {
        store.commit('auth/SET_ACCESS_TOKEN', value)
    }

    // eslint-disable-next-line class-methods-use-this
    setRefreshToken(value) {
        store.commit('auth/SET_REFRESH_TOKEN', value)
    }

    login(...args) {
        return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
    }

    logout() {
        return this.axiosIns.post(this.jwtConfig.logoutEndpoint)
    }

    register(...args) {
        return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
    }

    refreshToken() {
        return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
            accessToken: this.getToken(),
            refreshToken: this.getRefreshToken(),
        })
    }
}
